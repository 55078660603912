import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import Image1 from "../../assets/images/ModernMarketing.svg";
import Image2 from "../../assets/images/image2.png";
import Image3 from "../../assets/images/image3.png";
import { useTranslation } from "react-i18next";
import { footerData } from "./FooterData";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import Newsletter from "../../assets/images/MM_newsletter.svg";

//--------Create a Footer component----------
function Footer() {
  //set language
  const { t } = useTranslation();
  const location = useLocation();
  const Year = new Date().getFullYear();

  // --------function for open component top of the page ----------
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Container className="screenOverride">
        {location.pathname == "/classifieds" ||
        location.pathname == "/job-types" ? (
          <div className="bannerImg">
            <iframe
              src={process.env.REACT_APP_CLASSIFIED_FOOTER_IFRAME_URL}
              style={{
                width: "100%",
                height: "95%",
                margin: "0px",
                border: "none",
                scrollBehavior: "none",
                allowfullscreen: "true",
              }}
            ></iframe>
          </div>
        ) : (
          ""
        )}
      </Container>
      <section className="footer">
        <Container className="footerOverRide">
          <div className="footerContent">
            <Row>
              <Col lg={4} md={4} sm={4}>
                <div className="signAfricaImg">
                  <a href="https://modernmarketing.co.za">
                    <img src={Image1} alt="sign-Africa" />
                  </a>
                </div>
                <p className="signAfricaTxt">{t("FOOTER_DES")}</p>
                <h5 className="beWithTxt">{t("SOCIAL_WITH")}</h5>
                <div className="iconWrapper">
                  <div className="socialIcon">
                    {footerData.sociallogo.map((item, index) => (
                      <a href={item.link} key={index} target="blank">
                        <img src={item.logo} alt="social-logo" />
                      </a>
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="linkHeading">
                  <div className="newsletterImg">
                    <a href="https://modernmarketing.co.za/subscribe/">
                      <img src={Newsletter} alt="news_letter" />
                    </a>
                  </div>
                  <div className="linkNames">
                    {footerData.new_links.map((item2, index2) => (
                      <a
                        href={item2.link}
                        target={item2.id == 3 ? "_blank" : "_parent"}
                        key={index2}
                      >
                        <h6>{item2.text}</h6>
                      </a>
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4} sm={4}>
                <div className="linkHeadingWrapper">
                  <h5>{t("DOWNLOAD_APP")}</h5>
                  <div className="appStoresImg">
                    {footerData.socialapp.map((item1, index1) => (
                      <a href={item1.url} target="blank" key={index1}>
                        <img src={item1.socialapp} alt="apps" />
                      </a>
                    ))}
                  </div>
                  <div className="publishInfoWrapper">
                    <p>{t("PUBLISH_HEAD")}</p>
                    <span>
                      The Kloof Mall, 19 Kloof Road, Bedfordview, Johannesburg,
                      South Africa
                    </span>
                    <p>+27 11 568 1894</p>
                    <p>info@modernmarketing.co.za</p>
                  </div>
                </div>
              </Col>
            </Row>
            <p className="footerEndTxt">
              Ⓒ MODERN MARKETING {Year}. All Rights Reserved |{" "}
              <a href="https://modernmarketing.co.za/privacy-statement/">
                Privacy
              </a>{" "}
              |{" "}
              <a href="https://modernmarketing.co.za/submit-news/">
                Submit News |{" "}
              </a>
              <a href="https://modernmarketing.co.za/subscribe/">
                Subscribe to Weekly Newsletter
              </a>
            </p>
          </div>
        </Container>
      </section>
    </>
  );
}
export default Footer;
