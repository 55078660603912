//---------------- Common Text----------------------
export const TRANSLATIONS_EN = {
  CREATEACCOUNT: "CREATE ACCOUNT",
  SUBSCRIPTION_TEXT: "Subscribe to our newsletter",
  EXISTING_ACCOUNT: "Already have an account?",
  LOGIN: "LOGIN",
  LOGIN_IN: "Login",
  SIGNUP: "Sign Up",
  LOGIN_EMAIL: "Login with Email",
  LOGIN_GOOGLE: "Login with Google",
  LOGIN_FACEBOOK: "Login with Facebook",
  LOGIN_APPLE: "Login with Apple",
  LOGIN_LINKEDIN: "Login with Linkedin",
  DONT_ACCOUNT: "Don't have an account?",
  EMAIL_LOGIN: "EMAIL LOGIN",
  FORGOT_PASSWORD: "Forgot password?",
  LOGIN_WITH: "or login with",
  CREATE_PROFILE: "CREATE PROFILE",
  LOG_IN: "LOG IN",
  LOG_OUT: " LOG OUT",
  VIEW_PROFILE: "VIEW PROFILE",
  BOOKMARKS: "BOOKMARKS",
  RESET_PASSWORD: "RESET YOUR PASSWORD",
  PASSWORD_TEXT: "A new password will be sent to the entered email address.",
  RESET_PASS: "RESET PASSWORD",
  NEW_PASS: "NEW PASSWORD SENT",
  PASS_SENT: "A new password will be sent to",
  RESEND: "Resend",
  CHANGE_EMAIL: "Change email address",
  EMAIL_VERIFICATION: "EMAIL VERIFICATION",
  VARIFICATION_TEXT: `To verify your email address  
  please enter the code sent to`,
  VERIFY: "VERIFY",

  //---------- Classified Common Text---------------
  FOR_SALE: "FOR SALE",
  WANTED: "WANTED",
  JOB_OFFERS: "JOB OFFERS",
  JOB_SEEKER: "JOB SEEKER",
  PER_WEEK: "PER WEEK",
  COMPANY: "COMPANY",
  CONTACT_PERSON_EMAIL: "EMAIL",
  CONTACT_NUMBER: "CONTACT NUMBER",
  WHATSAPP: "WhatsApp",
  SHARE: "SHARE",
  NOCLASSIFIED_DISPLAY: "NO CLASSIFIEDS TO DISPLAY",
  SEARCH_TEXT: "Search Location",
  CLASSIFIED_LIST_RESULT: "Results",
  COUNTRY_NAME: "All South Africa",
  DONE_BUTTON: "Done",
  CLASSIFIED_CONTACT_PERSON: "Contact Person",
  LOADMORE_BUTTON: " Load More",
  REFRENCE_ID: "all",
  ALL_CLASSFIED: "All",
  ALL_COUNTRY: "All Country",
  EDIT_ADVERT: "EDIT / DELETE ADVERT",
  NOT_LIVE: "NOT LIVE - Pending Approvals",
  NOCLASSIFIEDSALES_DISPLAY: "NO SALES TO DISPLAY",
  NOCLASSIFIEDWANTED_DISPLAY: "NO WANTED TO DISPLAY",
  NOCLASSIFIEDJOB_DISPLAY: " NO JOBS TO DISPLAY",

  //----Footer Text------
  FOOTER_TEXT: `Sign Africa provides the latest news, insights and trends in wide format, garment & textile print, branding and all visual communications in Southern Africa.`,
  QUICK_LINKS: "QUICK LINKS",
  NEWS_LINKS: "News",
  JOB_LINKS: "Jobs",
  ADVERTIES_LINKS: "Advertise",
  OUR_MEDIA: "OUR MEDIA",
  DEALS_LINKS: "Deals/Hub",
  CLASSIFIEDS_LINKS: " Classified",
  POST_ADVERT_LINKS: "Post Advert",
  YOUR_ADS_LINKS: "Your ADS",
  LATEST_DEALS_LINKS: " Latest Deals",
  PRODUCTS_LINKS: "Products",
  SERVICES_LINKS: "Services",
  BRANDS_LINKS: "Brands",
  COMPANIES_LINKS: "Companies",
  BOOKMARKS_LINKS: "Bookmarks",
  PROFILE_LINKS: "View Profile",

  //----Header Text------
  DEALS: "DEALS/HUB",
  CLASSIFIEDS: " CLASSIFIEDS",
  JOBS: "JOBS",
  POST_ADVERT: "POST ADVERT",
  YOUR_ADS: "YOUR ADS",
  LATEST_DEALS: " LATEST DEALS",
  PRODUCTS: "PRODUCTS",
  SERVICES: "SERVICES",
  BRANDS: "BRANDS",
  COMPANIES: "COMPANIES",

  //----Profile Text------
  YOUR_PROFILE: "YOUR PROFILE",
  PROFILE: "Your Profile Details",
  PROFILE_PARA: "Tell us a little about you, used for classifieds",
  PROFILE_DETAILS:
    "A profile is required to post and manage classified adverts. You can create or edit yout profile here.",
  LOCATION: "Your Location",
  LOCATION_PARA: "Not public, used for your classified adverts",
  PROFILE_PHOTO: "Your Profile Photo",
  ADD_PHOTO: "Add in profile photo for company logo",
  ADD: "ADD",
  CLEAR: "CLEAR",
  YOUR_PASSWORD: "Your Password",
  PASSWORD_PARA: "Manage your password here.",
  CHANGE_PASSWORD: "CHANGE PASSWORD",
  DELETE_ACCOUNT: "DELETE ACCOUNT",
  SAVE: "SAVE",
  NOTE: "PLEASE NOTE",
  ALERT: "Alert",
  WANT_DELETE: "Are you sure? You want to delete your account.",
  CANCEL: "CANCEL",
  DELETE: "DELETE",
  SOUTH_AFRICA_SET: "South Africa",
  OUTOF_SOUTH: "Outside South Africa",
  Error_text: "Profile updated successfully.Please login with new password.",

  //----Deals Text------
  CONTACT_PERSON: "CONTACT PERSON",
  WEBSITE: "WEBSITE",
  EXPIRY: "EXPIRY",
  EMAIL_TEXT: "EMAIL",
  PROMOTION_CODE: "PROMOTIONAL CODE",
  WHATSAPP_ME: "WhatsApp Me",
  COMPANY_PROFILE: "View Company Profile",
  BRAND_REPRESENT: "BRANDS REPRESENTED",
  ADDRESS: "ADDRESS",
  RECENT: "Recently Updated",
  ALPHABETICAL: "Alphabetical",
  NO_PRODUCT: "--- NO PRODUCTS TO DISPLAY ---",
  NO_SERVICE: "--- NO SERVICES TO DISPLAY ---",
  NO_BRAND: "--- NO BRANDS TO DISPLAY ---",
  NO_DEAL: "--- NO DEALS TO DISPLAY ---",
  DEAL: "DEAL",
  DEALS_TEXT: "DEALS",

  //---------------Error Messages Common Text----------
  INCOMPLETE: "Form incomplete, please fill all fields",
  INVALID_EMAIL: "Invalid Email Address",
  EMAIL_MAXLENGTH: "Email must be 50 character or small",
  INVALID_PASSWORD:
    "Password should have 1 upper case, 1 lower case, 1 number, 1 special character min 4 char.",
  PASS_MAXLENGTH: "Password must be 8 character or small",
  PASS_MINLENGTH: "password must be 4 character or long",
  INVALID_NAME: "Please enter valid name",
  NAME_MAXLENGTH: "Name must be 20 character or small",
  NAME_MINLENGTH: "Please enter atleast 3 character",
  ENTER_NAME: "please enter full name",
  ENTER_EMAIL: "please enter email address",
  NEW_PASSWORD: "Please Enter New Password",
  REPEAT_PASS: "Please Enter Repeat Password",
  NOT_MATCH: "Repeat password does not match",
  MATCH_PASS: "Passwords have to match",
  CURRENT_PASS: "Please Enter current password",

  //---------- Placeholder Common Text---------------
  NAME: "Full Name",
  EMAIL: "Your Email",
  PASSWORD: "Your Password",
  CONFIRM_PASSWORD: "Confirm Password",
  SEARCH: "Search",
  COMPANY_NAME: "Company Name (Optional)",
  POSITION: "Position/Occupation (Optional)",
  CITY: "Input City/Town",
  SET_PASSWORD: "Set New Password",
  REPEAT_PASSWORD: "Repeat Password",
  CURRENT_PASSWORD: "Current Password",
  SELECT_PROVINCE: "Select Province",
  COUNTRY_SET: "Select Country",
  CITY_TEXT: "Input City/Town",

  //---------- Form Lable Common Text---------------
  REMEMBER_ME: "Remember me",
  YOUR_NAME: "Your Name (Optional)",
  YOUR_EMAIL: "Your Email (Required)",

  //---------- Status Error Text---------------
  SESSION_EXPIRE: "Your Session Expired!",

  //------------- Post Adever-------------
  PLEASE_NOTE: `PLEASE NOTE`,
  POST_CLASSIFIED_AD: `POST CLASSIFIED AD`,
  ALL_ADVERT_REMOVE_60: `All adverts are subject to approval. Adverts will be removed after 60 days. Delete or Edit your advert anytime`,
  CATEGORY: `CATEGORY`,
  SELECT_CATEGORY: `Select a Category`,
  ADD_DETAIL: `AD DETAILS`,
  ADVERT_HEADING: `Advert Heading`,
  ADD_ADEVERT_TITLE: `Add an Adver Title`,
  ADVERT_DESCRIPTION: `Advert Description`,
  INCLUDE_DESCRIPTION: `Include a Description`,
  JOB_TYPE: `JOB TYPE`,
  SELECT_JOB_TYPE: `SELECT JOB TYPE`,
  ON_HYBRID_REMOTE: `ON SITE, HYBRID OR REMOTE?`,
  SELECT_OPTION: `Select Option`,
  EMPLOYMENT_EQUITY: `EMPLOYMENT EQUITY`,
  PRICE: `PRICE`,
  RENUMERATION: `RENUMERATION`,
  PLEACE_ENTER_AMOUNT: `Please enter amount`,
  MIN_AMOUNT_VALUE: `Please fill valid amount`,
  PLEASE_FILL_AMOUNT: `Please Fill Amount`,
  SELECT_EARNING_OPTION: `SELECT EARNING OPTION`,
  PLEACT_CLICK: `Please checkbox Click`,
  LOCATIONS: `LOCATION`,
  INPUT_CITY_TOWN: `Input City/Town`,
  CONTACT_DETAILS: `CONTACT DETAILS`,
  INPUT_FULL_NAME: `Input Your Full Name`,
  ENTER_COMANY_NAME: `Enter Company Name`,
  ADD_PHOTOS: `ADD PHOTOS`,
  POST_HEADING_MIN_ERROR: `Heading should have minimum length 3 characters.`,
  POST_Description_MIN_ERROR: `Description should have minimum length 3 characters.`,
  SELECT_COUNTRY: `Selected Country`,
  PLEASE_SEL_CURR: `PLEASE SELECT CURRENCY`,
  DELETE_ADVERT: "Are you sure  You want to delete?",
  SELECT_PROVIN: `Selected Province`,
  ENTER_HEAD: "Enter Heading",
  ENTER_DISCRIPT: "Enter description",
  AMOUNT_OPTIONAL: "Amount (optional)",
  OPTINAL_COMPANY: "Company Name (optional)",

  //---------Login Alert Box---------
  LOGIN_REQUIRED: "LOGIN REQUIRED",
  LOGIN_SUB_TEXT:
    "You need to create a profile in order to post an item. Login now if you have a profile or create one.",
  CREATE_FREE_PROFILE: "Create FREE Profile",
  OR: "OR",
  LOGIN_TO_PROFILE: "Login to your Profile",

  //-------Book mraks module--------
  BOOK_MARK_HEADING: "BOOKMARKED",
  BOOK_MARK_SUBHEAD: "PLEASE NOTE",
  BOOK_MARK_DETAILTEXT: "All bookmarked items are subject to expiration.",
  YOURS_BOOK_MARKS: "YOUR BOOKMARKS",
  NO_BOOK_MARKS: "--- NO BOOKMARKS TO DISPLAY ---",
  CLEAR_ALL: "Clear All",
  REMOVE_ALL_BOOKMARK: "Are you sure ? You want to delete all bookmark.",
  LOAD_MORE: "Load More",

  //------------- Your Ads-------------
  YOUR_ADVERTS: "YOUR ADVERTS",
  ADVERT_NOTE:
    "All adverts are subject to approval. Adverts will be removed after 60 days. Delete or Edit your advert anytime",
  NO_ADVERT: "--- NO ADVERTS TO DISPLAY ---",

  EDIT_DELETE_BTN: "EDIT / DELETE ADVERT",
  PENDING_APPROVAL: "NOT LIVE - Pending Approvals",
  NO_SEARCH_DATA: "--- NO SEARCH FOUND ---",
  NO_BANNER_DATA: "--- NO BANNER FOUND ---",
  TOOLTIP_TEXT: "You can access website except post advert and bookmark.",
  TMG_UPLOAD_ERROR: "Only 5 files are allowed to upload.",
  NUMBER: "number",
  HOME: "HOME",
  NEWS: "NEWS",
  EXPOS: "EXPOS",
  EVENTS: "EVENTS",
  CONATCT_US: "CONTACT US",
  ADVERTISE: "ADVERTISE",
  FOOTER_DES: `Modern Marketing is a news site and weekly newsletter that covers branding,
   products, technology, trends and campaigns across the marketing and branding industries.
   It’s an evolved mix of strategic marketing, traditional marketing, internet and new media.`,
  SOCIAL_WITH:"BE SOCIAL WITH US",
  DOWNLOAD_APP:"Download the APP",
PUBLISH_HEAD:"Published by Practical Publishing",

};
