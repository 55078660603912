//---------------- Common Text----------------------
export const TRANSLATIONS_ES = {
  CREATEACCOUNT: "CREAR UNA CUENTA",
  SUBSCRIPTION_TEXT: "Suscríbete a nuestro boletín",
  EXISTING_ACCOUNT: "Ya tienes una cuenta?",
  LOGIN: "ACCESO",
  LOGIN_IN: "Acceso",
  SIGNUP: "Inscribirse",
  LOGIN_EMAIL: "Iniciar sesión con correo electrónico",
  LOGIN_GOOGLE: "Iniciar sesión con Google",
  LOGIN_FACEBOOK: "Iniciar sesión con Facebook",
  LOGIN_APPLE: "Iniciar sesión con Apple",
  LOGIN_LINKEDIN: "Ingresar con LinkedIn",
  DONT_ACCOUNT: "no tengo una cuenta?",
  EMAIL_LOGIN: "INICIO DE SESIÓN DE CORREO ELECTRÓNICO",
  FORGOT_PASSWORD: "Has olvidado tu contraseña ?",
  LOGIN_WITH: "o inicia sesión con",
  CREATE_PROFILE: "CREAR PERFIL",
  LOG_IN: "ACCESO",
  LOG_OUT: " CERRAR SESIÓN",
  VIEW_PROFILE: "VER PERFILE",
  BOOKMARKS: "MARCAPÁGINAS",
  RESET_PASSWORD: "RESTABLECER SU CONTRASEÑA",
  PASSWORD_TEXT:
    "Se enviará una nueva contraseña a la dirección de correo electrónico ingresada.",
  RESET_PASS: "RESTABLECER LA CONTRASEÑA",
  NEW_PASS: "NUEVA CONTRASEÑA ENVIADA",
  PASS_SENT: "Se enviará una nueva contraseña a",
  SENT_EMAIL: "ejemplo@email.com",
  RESEND: "reenviar",
  CHANGE_EMAIL: "Cambiar dirección de correo electrónico",
  EMAIL_VERIFICATION: `Para verificar su dirección de correo electrónico
    por favor ingrese el código enviado a
    ejemplo@email.com`,
  VERIFY: "VERIFICAR",

  //---------- Classified Common Text---------------
  FOR_SALE: "EN VENTA",
  WANTED: "BUSCADO",
  JOB_OFFERS: "OFERTAS DE TRABAJO",
  JOB_SEEKER: "DEMANDANTE DE EMPLEO",
  PER_WEEK: "POR SEMANA",
  CONTACT_PERSON: "Persona de contacto",
  COMPANY: "COMPAÑÍA",
  CONTACT_PERSON_EMAIL: "CORREO ELECTRÓNICO",
  CONTACT_NUMBER: "NÚMERO DE CONTACTO",
  WHATSAPP: "WhatsApp",
  SHARE: "COMPARTIR",
  NOCLASSIFIED_DISPLAY: "NO HAY CLASIFICADOS PARA MOSTRAR",
  SEARCH_TEXT: "Buscar ubicación",
  CLASSIFIED_LIST_RESULT: "Resultados",
  COUNTRY_NAME: "Toda Sudáfrica",
  DONE_BUTTON: "Hecho",
  CLASSIFIED_CONTACT_PERSON: "Persona de contacto",
  LOADMORE_BUTTON: "Carga más",
  REFRENCE_ID: "todo",
  ALL_COUNTRY: "Todo el país",
  OUTOF_SOUTH: "Fuera de Sudáfrica",
  EDIT_ADVERT: "EDITAR / ELIMINAR ANUNCIO",
  NOT_LIVE: "NO ACTIVO - Aprobaciones pendientes",
  NOCLASSIFIEDSALES_DISPLAY: "SIN VENTAS PARA MOSTRAR",
  NOCLASSIFIEDWANTED_DISPLAY: "NO QUERÍA MOSTRAR",
  NOCLASSIFIEDJOB_DISPLAY: "NO HAY TRABAJOS PARA MOSTRAR",

  //----Footer Text------
  FOOTER_TEXT: `Sign Africa proporciona las últimas noticias, conocimientos y tendencias en gran formato, impresión de prendas y textiles, creación de marcas y todas las comunicaciones visuales en el sur de África..`,
  QUICK_LINKS: "ENLACES RÁPIDOS",
  NEWS_LINKS: "Noticias",
  JOB_LINKS: "Trabajos",
  ADVERTIES_LINKS: "Anunciar",
  OUR_MEDIA: "NUESTROS MEDIOS",
  DEALS_LINKS: "Ofertas/Centro",
  CLASSIFIEDS_LINKS: "Clasificada",
  POST_ADVERT_LINKS: "Anuncio Postal",
  YOUR_ADS_LINKS: "Tus ANUNCIOS",
  LATEST_DEALS_LINKS: "Últimas ofertas",
  PRODUCTS_LINKS: "Productos",
  SERVICES_LINKS: "Servicios",
  BRANDS_LINKS: "Marcas",
  COMPANIES_LINKS: "Compañías",
  BOOKMARKS_LINKS: "Marcadores",
  PROFILE_LINKS: "Ver perfil",

  //----Header Text------
  DEALS: "OFERTAS/CENTRO",
  CLASSIFIEDS: "CLASIFICADOS",
  JOBS: "TRABAJOS",
  POST_ADVERT: "ANUNCIO POSTAL",
  YOUR_ADS: "SUS ANUNCIOS",
  LATEST_DEALS: " ÚLTIMAS OFERTAS",
  PRODUCTS: "PRODUCTOS",
  SERVICES: "SERVICIOS",
  BRANDS: "MARCAS",
  COMPANIES: "COMPAÑÍAS",

  //----Profile Text------
  YOUR_PROFILE: "TU PERFIL",
  PROFILE: "Detalles de su perfil",
  PROFILE_PARA: "Cuéntanos un poco sobre ti, usado para anuncios clasificados",
  PROFILE_DETAILS:
    "Se requiere un perfil para publicar y administrar anuncios clasificados. Puede crear o editar su perfil aquí.",
  LOCATION: "Tu ubicación",
  LOCATION_PARA: "No público, utilizado para sus anuncios clasificados",
  PROFILE_PHOTO: "Tu foto de perfil",
  ADD_PHOTO: "Agregue una foto de perfil para el logotipo de la empresa",
  ADD: "AGREGAR",
  CLEAR: "CLARA",
  YOUR_PASSWORD: "Tu contraseña",
  PASSWORD_PARA: "Gestiona tu contraseña aquí.",
  CHANGE_PASSWORD: "CAMBIAR LA CONTRASEÑA",
  DELETE_ACCOUNT: "BORRAR CUENTA",
  SAVE: "AHORRAR",
  NOTE: "TENGA EN CUENTA",
  ALERT: "Alerta",
  WANT_DELETE: "Está seguro? Quiere eliminar su cuenta.",
  CANCEL: "CANCELAR",
  DELETE: "BORRAR",
  SOUTH_AFRICA_SET: "Sudáfrica",
  Error_text: "Perfil actualizado con éxito. Inicie sesión con una nueva contraseña.",


  //----Deals Text------
  WEBSITE: "SITIO WEB",
  EXPIRY: "EXPIRACIÓN",
  EMAIL_TEXT: "CORREO ELECTRÓNICO",
  PROMOTION_CODE: "CODIGO PROMOCIONAL",
  WHATSAPP_ME: "Mándame un mensaje por Whatsapp",
  COMPANY_PROFILE: "Ver perfil de la empresa",
  BRAND_REPRESENT: "MARCAS REPRESENTADAS",
  ADDRESS: "DIRECCIÓN",
  RECENT: "Recientemente actualizado",
  ALPHABETICAL: "Alfabética",
  NO_PRODUCT: "--- NO HAY PRODUCTOS PARA MOSTRAR ---",
  NO_SERVICE: "--- NO HAY SERVICIOS PARA MOSTRAR ---",
  NO_BRAND: "--- NO HAY MARCAS PARA MOSTRAR ---",
  NO_DEAL: "--- NO HAY OFERTAS PARA MOSTRAR ---",
  DEAL: "TRATO",
  DEALS_TEXT: "OFERTAS_TEXTO",

  //---------------Error Messages Common Text----------
  INCOMPLETE: "Formulario incompleto, por favor llene todos los campos",
  INVALID_EMAIL: "Dirección de correo electrónico no válida",
  EMAIL_MAXLENGTH: "El correo electrónico debe tener 50 caracteres o menos",
  INVALID_PASSWORD:
    "La contraseña debe tener 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial mínimo 4 caracteres.",
  PASS_MAXLENGTH: "La contraseña debe tener 15 caracteres o menos",
  PASS_MINLENGTH: "la contraseña debe tener 8 caracteres o más",
  INVALID_NAME: "Por favor, introduzca un nombre válido",
  NAME_MAXLENGTH: "El nombre debe tener 20 caracteres o menos",
  NAME_MINLENGTH: "Por favor ingrese al menos 3 caracteres",
  ENTER_NAME: "por favor ingrese el nombre completo",
  ENTER_EMAIL: "por favor ingrese la dirección de correo electrónico",
  NEW_PASSWORD: "Ingrese una nueva contraseña",
  REPEAT_PASS: "Ingrese la contraseña repetida",
  NOT_MATCH: "Repetir contraseña no coincide",
  MATCH_PASS: "Las contraseñas tienen que coincidir",
  CURRENT_PASS: "Por favor, introduzca la contraseña actual",

  //---------- Placeholder Common Text---------------
  NAME: "Nombre completo",
  EMAIL: "Tu correo electrónico",
  PASSWORD: "Tu contraseña",
  CONFIRM_PASSWORD: "confirmar Contraseña",
  SEARCH: "Buscar",
  COMPANY_NAME: "Nombre de la compañía (opcional)",
  POSITION: "Puesto/Ocupación (Opcional)",
  CITY: "Ciudad/pueblo de entrada",
  SET_PASSWORD: "Establecer nueva contraseña",
  REPEAT_PASSWORD: "Repita la contraseña",
  CURRENT_PASSWORD: "Contraseña actual",
  SELECT_PROVINCE: "Seleccione Provincia",
  COUNTRY_SET: "Seleccionar país",

  //---------- Form Lable Common Text---------------
  REMEMBER_ME: "Acuérdate de mí",
  YOUR_NAME: "Tu nombre (Opcional)",
  YOUR_EMAIL: "Tu Email (requerido)",

  //---------- Status Error Text---------------
  SESSION_EXPIRE: "¡Su sesión expiró!",
  //------------- Post Adever-------------
  PLEASE_NOTE: `TENGA EN CUENTA`,
  POST_CLASSIFIED_AD: `POST ANUNCIO CLASIFICADO`,
  ALL_ADVERT_REMOVE_60: `Todos los anuncios están sujetos a aprobación. Los anuncios se eliminarán después de 60 días. Elimine o edite su anuncio en cualquier momento`,
  CATEGORY: `CATEGORÍA`,
  SELECT_CATEGORY: `Seleccione una categoría`,
  ADD_DETAIL: `DETALLES DEL ANUNCIO`,
  ADVERT_HEADING: `Encabezado de anuncio`,
  ADD_ADEVERT_TITLE: `Agregar un título de anuncio`,
  ADVERT_DESCRIPTION: `Descripción del anuncio`,
  INCLUDE_DESCRIPTION: `Incluir una descripción`,
  JOB_TYPE: `EL TIPO DE TRABAJO`,
  SELECT_JOB_TYPE: `SELECCIONE EL TIPO DE TRABAJO`,
  ON_HYBRID_REMOTE: `EN EL SITIO, HÍBRIDO O REMOTO?`,
  SELECT_OPTION: `Seleccionar opción`,
  EMPLOYMENT_EQUITY: `EQUIDAD EN EL EMPLEO`,
  PRICE: `PRECIO`,
  RENUMERATION: `RENUMERACIÓN`,
  PLEACE_ENTER_AMOUNT: `Por favor ingrese la cantidad`,
  PLEASE_FILL_AMOUNT: `Por favor complete la cantidad`,
  SELECT_EARNING_OPTION: `SELECCIONE LA OPCIÓN DE GANANCIA`,
  PLEACT_CLICK: `Marque la casilla Haga clic en`,
  LOCATIONS: `UBICACIÓN`,
  INPUT_CITY_TOWN: `Ingresar Ciudad/Pueblo`,
  CONTACT_DETAILS: `DETALLES DE CONTACTO`,
  INPUT_FULL_NAME: `Ingrese su nombre completo`,
  ENTER_COMANY_NAME: `Introduzca el nombre de la empresa`,
  ADD_PHOTOS: `AGREGAR FOTOS`,
  POST_HEADING_MIN_ERROR: `El encabezado debe tener una longitud mínima de 3 caracteres.`,
  POST_Description_MIN_ERROR: `La descripción debe tener una longitud mínima de 3 caracteres.`,
  SELECT_COUNTRY: `País seleccionado`,
  PLEASE_SEL_CURR: `SELECCIONE LA MONEDA`,
  DELETE_ADVERT: "Estas segura que quieres eliminar?",
  SELECT_PROVIN: `Provincia seleccionada`,
  ENTER_HEAD: "Ingresar encabezado",
  ENTER_DISCRIPT: "Introduce la descripción",
  AMOUNT_OPTIONAL: "Cantidad (opcional)",
  OPTINAL_COMPANY: "Nombre de la compañía (opcional)",

  //---------Login Alert Box---------
  LOGIN_REQUIRED: "NECESARIO INICIAR SESIÓN",
  LOGIN_SUB_TEXT:
    "Necesitas crear un perfil para publicar un artículo. Inicie sesión ahora si tiene un perfil o cree uno.",
  CREATE_FREE_PROFILE: "Crear perfil GRATIS",
  OR: "OR",
  LOGIN_TO_PROFILE: "Inicie sesión en su perfil",

  //-------Book mraks module--------
  BOOK_MARK_HEADING: "MARCADO",
  BOOK_MARK_SUBHEAD: "TENGA EN CUENTA",
  BOOK_MARK_DETAILTEXT:
    "Todos los artículos marcados están sujetos a vencimiento.",
  YOURS_BOOK_MARKS: "SUS MARCADORES",
  NO_BOOK_MARKS: "--- NO HAY MARCADORES PARA MOSTRAR ---",
  CLEAR_ALL: "Limpiar todo",
  REMOVE_ALL_BOOKMARK: "Está seguro ? Desea eliminar todos los marcadores.",
  LOAD_MORE: "Carga más",

  //------------- Your Ads-------------
  YOUR_ADVERTS: "TUS ANUNCIOS",
  ADVERT_NOTE: "Todos los anuncios están sujetos a aprobación. Los anuncios se eliminarán después de 60 días. Elimine o edite su anuncio en cualquier momento",
  NO_ADVERT: "--- SIN ANUNCIOS PARA MOSTRAR ---",

  EDIT_DELETE_BTN: "EDITAR / ELIMINAR ANUNCIO",
  PENDING_APPROVAL: "NO ACTIVO - Aprobaciones pendientes",
  NO_SEARCH_DATA: "--- NO SE ENCONTRÓ LA BÚSQUEDA ---",

  TOOLTIP_TEXT: "Puede acceder al sitio web, excepto publicar anuncios y marcadores.",
  TMG_UPLOAD_ERROR: "Solo se permite cargar 5 archivos.",
  NUMBER: "número",
  HOME:"HOGAR",
  NEWS:"NOTICIAS",
  EXPOS:"EXPOSICIONES",
  EVENTS:"EVENTOS",
  CONATCT_US:"CONTÁCTENOS",
  ADVERTISE:"ANUNCIAR",
  FOOTER_DES: `Modern Marketing es un sitio de noticias y un boletín semanal que cubre marcas,
  productos, tecnología, tendencias y campañas en las industrias de marketing y branding.
  Es una combinación evolucionada de marketing estratégico, marketing tradicional, Internet y nuevos medios.`,
  SOCIAL_WITH:"SEA SOCIAL CON NOSOTROS",
  DOWNLOAD_APP:"Descarga la APLICACIÓN",
PUBLISH_HEAD:"Publicado por Publicación práctica",
};
