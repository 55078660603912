import "./TopHeader.css";
import Logo from "../../../assets/images/MM-web-logo-1146x200.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoSearchSharp } from "react-icons/io5";
import MenuImg from "../../../assets/images/toggle_pic.png";
import { useState } from "react";

function TopHeader() {
  const [isMenuOpn, setIsMenuOpn] = useState(false);
  console.log("isMenuOpn", isMenuOpn);
  return (
    <>
      <section className="topHeaderWrapper">
        <div className={isMenuOpn ? "topHeader movedHeader" : "topHeader"}>
          <Navbar expand="lg" className="bg-body-tertiary headerContent">
            <Container>
              <Navbar.Toggle
                onClick={() => setIsMenuOpn(!isMenuOpn)}
                aria-controls="basic-navbar-nav"
              >
                MENU <img src={MenuImg} />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="https://modernmarketing.co.za">Home</Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/news/">
                    NEWS
                  </Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/live/">
                    LIVE
                  </Nav.Link>
                  <Nav.Link href="https://hub.modernmarketing.co.za/deals/products">
                    PRODUCT FINDER
                  </Nav.Link>
                  <Nav.Link href="https://hub.modernmarketing.co.za/job-types">
                    JOBS
                  </Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/calendar/">
                    EVENTS
                  </Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/submit-news/">
                    SUBMIT NEWS
                  </Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/subscribe/">
                    SUBSCRIBE
                  </Nav.Link>
                  <Nav.Link href="https://modernmarketing.co.za/contact-us/">
                    CONTACT US
                  </Nav.Link>
                  {/* <NavDropdown title={<IoSearchSharp/>} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className={isMenuOpn ? "logoImgMargin" : "logoImg"}>
          <img src={Logo} alt="news-logo" />
        </div>
      </section>
    </>
  );
}

export default TopHeader;
